import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from '../../../core/models/response.model';
import {
  ChangePasswordBody,
  ForgotPasswordBody,
} from '../models/password.model';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordRepository {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  forgotPassword(body: ForgotPasswordBody): Observable<IResponse> {
    return this.http.post<IResponse>(`${this.baseUrl}/password/forgot`, body);
  }

  changePassword(
    body: ChangePasswordBody,
    token: string
  ): Observable<IResponse> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer '+ token,
    });
    let options = { headers: headers };

    return this.http.post<IResponse>(
      `${this.baseUrl}/password/reset`,
      body,
      options
    );
  }
}
