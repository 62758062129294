<main>
  <section class="password-section">
    <div class="box" *ngIf="!isSuccessfully">
      <div class="title">
        <h2>Alterar Senha</h2>
        <p>Informe sua nova senha</p>
      </div>
      <div class="form">
        <form [formGroup]="form" class="form">
          <div class="item">
            <mat-form-field appearance="outline">
              <mat-label>Senha</mat-label>
              <input
                matInput
                #newPasswordInput
                type="password"
                id="password"
                [formControlName]="'new_password'"
                placeholder="Digite sua nova senha"
              />
              <mat-icon
                matSuffix
                (click)="
                  hide = !hide;
                  newPasswordInput.type =
                    newPasswordInput.type === 'password' ? 'text' : 'password'
                "
                >{{ hide ? "visibility_off" : "visibility" }}</mat-icon
              >
            </mat-form-field>
          </div>
          <div class="item">
            <mat-form-field appearance="outline">
              <mat-label>Senha</mat-label>
              <input
                matInput
                #confirmPasswordInput
                type="password"
                id="password"
                [formControlName]="'confirm_password'"
                placeholder="Repita sua nova senha"
              />
              <mat-icon
                matSuffix
                (click)="
                  hide = !hide;
                  confirmPasswordInput.type =
                    confirmPasswordInput.type === 'password'
                      ? 'text'
                      : 'password'
                "
                >{{ hide ? "visibility_off" : "visibility" }}</mat-icon
              >
            </mat-form-field>
          </div>
          <button
            mat-button
            class="filled"
            (click)="change()"
            *ngIf="!isLoading"
          >
            Entrar
          </button>
          <div class="loader" *ngIf="isLoading">
            <mat-spinner
              diameter="24"
              strokeWidth="2"
              class="spinner-position"
            ></mat-spinner>
          </div>
        </form>
      </div>
    </div>

    <div class="box" *ngIf="isSuccessfully">
      <div class="title">
        <h2>Senha alterada com sucesso!</h2>
        <img src="/assets/images/email-green.png" height="96" />
        <p>
          Agora você pode usar sua nova senha para acessar sua conta
          normalmente.
        </p>
      </div>

      <button mat-button class="outlined" (click)="onBack()">Voltar</button>
    </div>
  </section>
</main>
