import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '../../../shared/services/feedback.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ChangePasswordBody } from '../models/password.model';
import { ForgotPasswordService } from '../services/forgot-password.service';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIcon,
    RouterModule,
    MatProgressSpinner,
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent implements OnInit {
  form!: FormGroup;
  hide = true;
  isLoading: boolean = false;
  isSuccessfully: boolean = false;

  token: string = '';
  email: string = '';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private forgotPasswordService: ForgotPasswordService,
    private feedbackService: FeedbackService,
    private _activatedRoute: ActivatedRoute
  ) {
    this.form = this.formBuilder.group({
      new_password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]],
    });

    this._activatedRoute.queryParams.subscribe((params) => {
      const token = params['token'] || null;
      const email = params['email'] || null;

      if (token) {
        this.token = token;
      }
      if (email) {
        this.email = email;
      }
    });
  }

  ngOnInit(): void {}

  async change(): Promise<void> {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    const body = this.form.getRawValue() as ChangePasswordBody;
    body.email = this.email;

    this.forgotPasswordService.change(body, this.token).subscribe({
      next: (res) => {
        if (res) {
          this.isLoading = false;
          this.isSuccessfully = true;
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.feedbackService.httpError(err);
      },
    });
  }

  onBack(): void {
    this.router.navigate(['/login']);
  }
}
