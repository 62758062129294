import { Routes } from '@angular/router';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { AuthComponent } from './modules/auth/auth.component';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { authGuard } from './core/guards/auth.guard';
import { ChangePasswordComponent } from './modules/auth/change-password/change-password.component';

export const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login', component: AuthComponent },
  { path: 'recuperar-senha', component: ForgotPasswordComponent },
  { path: 'alterar-senha', component: ChangePasswordComponent },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [authGuard],
  },
  { path: '**', component: NotFoundComponent },
];
