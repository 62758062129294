import { Injectable } from '@angular/core';
import { ForgotPasswordRepository } from '../repositories/forgot-password.repository';
import { FeedbackService } from '../../../shared/services/feedback.service';
import { catchError, map, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IResponse } from '../../../core/models/response.model';
import {
  ChangePasswordBody,
  ForgotPasswordBody,
} from '../models/password.model';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordService {
  constructor(
    private repository: ForgotPasswordRepository,
    private feedbackService: FeedbackService
  ) {}

  forgot(body: ForgotPasswordBody): Observable<IResponse> {
    return this.repository.forgotPassword(body).pipe(
      map((res) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        this.feedbackService.httpError(err);
        throw err;
      })
    );
  }

  change(body: ChangePasswordBody, token: string): Observable<IResponse> {
    return this.repository.changePassword(body, token).pipe(
      map((res) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        this.feedbackService.httpError(err);
        throw err;
      })
    );
  }
}
